// https://dev.to/codebucks/how-to-get-user-s-location-in-react-js-1691

export const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

export const errors = (err: any) =>
  console.warn(`ERROR(${err.code}): ${err.message}`);
