import { Activity } from "../dto";

export const getKinds = (activities: Activity[]): String[] => {
  if (!Array.isArray(activities)) return ["all"];

  let activitiesKinds: String[] = ["all"];
  activities.forEach((activity: Activity) => {
    const activityKinds = activity.kinds || [];
    activityKinds.forEach((kind) => {
      if (!activitiesKinds.includes(kind)) {
        activitiesKinds.push(kind);
      }
    });
  });

  return activitiesKinds;
};
