import { palette } from "./palette";
export const color = {
  palette,

  main: palette.grey,
  primary: palette.orange,
  secondary: palette.blueGrey,
  font: palette.white,
  fontLighter: palette.whiteLighter,
  greenStatus: palette.green,
  yellowStatus: palette.yellow,
};
