import { FC, useCallback, useState, useMemo, useEffect } from "react";
import { Container, Field, Button, Input, Select, Option } from "./Form.style";
import { ReactComponent as MagnifyingGlass } from "../../assets/magnifying-glass.svg";
import { ReactComponent as LocationPin } from "../../assets/location-pin.svg";
import { ReactComponent as Calendar } from "../../assets/calendar.svg";
import { apiResponseState } from "../../store/activities.store";
import axios from "axios";
import { useSetRecoilState } from "recoil";
import ClipLoader from "react-spinners/ClipLoader";
import { color } from "../../theme";
import LocationField from "./LocationField";
import { kindsState } from "../../store/activities.filter.store";
// import { Activity } from "../../dto";
import { getKinds } from "../../utils/activity-kinds";
import { options, errors } from "../../utils/geolocation";

const menuItems = [
  {
    id: "interesting_places",
    name: "Lieux intéressants",
  },
  {
    id: "amusements",
    name: "Divertissements",
  },
  {
    id: "sport",
    name: "Sport",
  },
  {
    id: "tourist_facilities",
    name: "Installations touristiques",
  },
  {
    id: "accomodations",
    name: "Logements",
  },
  {
    id: "adult",
    name: "Adulte",
  },
];

interface addressObject {
  addressDetails?: {
    postal_code: string;
  };
  coordinates?: {};
  formatted_address?: string;
}

const Form: FC = () => {
  const setApiResponse = useSetRecoilState(apiResponseState);
  const [loading, setLoading] = useState(false);
  const [selectedActivityKind, setSelectedActivityKind] = useState("");
  const [startLocation, setStartLocation] = useState<addressObject>({});
  const [visitDay, setVisitDay] = useState<Date | undefined>(undefined);
  const setKinds = useSetRecoilState(kindsState);

  const [fieldAddress, setFieldAddress] = useState("");

  const [coords, setCoords] = useState({
    latitude: undefined,
    longitude: undefined,
  });

  const success = (pos: any) => {
    const crd = pos.coords;
    setCoords(crd);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            // We have permission to access location, so we can call our function directly!
            navigator.geolocation.getCurrentPosition(success);
          } else if (result.state === "prompt") {
            // User will get a popup asking for permission!
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            // User denied sharing location.
          }
          result.onchange = function () {
            console.log(result.state);
          };
        });
    } else {
      alert("Désolé, nous ne pouvons pas accéder à votre position");
    }
  }, []);

  useEffect(() => {
    if (coords.latitude && coords.longitude) {
      axios
        .get(
          `https://nominatim.openstreetmap.org/reverse?lat=${coords.latitude}&lon=${coords.longitude}&format=json`
        )
        .then((res) => {
          const address = res.data.address;

          const formattedAddress = `${address.house_number} ${address.road} ${address.town}`;

          setStartLocation({
            addressDetails: { postal_code: address?.postcode },
            coordinates: {
              lng: coords.longitude,
              lat: coords.latitude,
            },
            formatted_address: formattedAddress,
          });

          setFieldAddress(formattedAddress);
        });
    }
  }, [coords]);

  const requestBody = useMemo(() => {
    return {
      activityKind: selectedActivityKind,
      address: startLocation,
      visitDay: visitDay?.toString(),
    };
  }, [selectedActivityKind, startLocation, visitDay]);

  const getActivities = useCallback(() => {
    if (
      requestBody.activityKind &&
      requestBody.address &&
      requestBody.visitDay
    ) {
      setLoading(true);

      axios
        .post(`https://api.suricate.iomentum.com/activities`, requestBody)
        .then((res) => {
          setApiResponse(res.data);

          setKinds(getKinds(res.data.activities || []));
        })
        .catch(console.log)
        .finally(() => setLoading(false));
    }
  }, [requestBody, setApiResponse, setKinds]);

  return (
    <Container>
      <Field>
        <MagnifyingGlass />
        <Select
          name="activity-kinds"
          id="type-select"
          onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
            setSelectedActivityKind(event.target.value as string)
          }
        >
          <Option value="">Quelle activité ?</Option>
          {menuItems.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Field>

      <Field>
        <LocationPin />
        <LocationField
          setAddress={(event: any) => {
            setCoords({
              latitude: event.coordinates.lat,
              longitude: event.coordinates.lng,
            });
            setStartLocation(event);
          }}
          fieldAddress={fieldAddress}
          setFieldAddress={setFieldAddress}
        />
      </Field>

      <Field style={{ borderRight: "none" }}>
        <Calendar />
        <Input
          type="date"
          name="visit-day"
          id="visit-day"
          onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
            setVisitDay(new Date(event.target.value as string))
          }
        />
      </Field>

      <Button onClick={getActivities}>
        {loading ? (
          <ClipLoader color={color.font} size={20} />
        ) : (
          <MagnifyingGlass />
        )}
      </Button>
    </Container>
  );
};

export default Form;
