import styled from "styled-components";
import { color } from "../../theme";

export const Container = styled.div`
  background-color : ${color.main};
  display: flex;
  flex-direction column;
  align-items: center;
  padding-bottom: 50px;
  color: ${color.font};
`;

export const GridBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 40px 40px;
  grid-template-areas: ". . . .";
  margin-top: 50px;
`;
