import React from "react";

function FacebookLogoSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      fill="none"
      viewBox="0 0 31 31"
    >
      <path
        fill="#B3442B"
        fillRule="evenodd"
        d="M.997 15.949c0 7.458 5.416 13.659 12.5 14.916V20.032h-3.75v-4.167h3.75v-3.334c0-3.75 2.416-5.832 5.834-5.832 1.082 0 2.25.166 3.332.332v3.834h-1.916c-1.834 0-2.25.916-2.25 2.084v2.916h4l-.666 4.167h-3.334v10.833c7.084-1.257 12.5-7.457 12.5-14.916 0-8.296-6.75-15.084-15-15.084s-15 6.788-15 15.084z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default FacebookLogoSvg;
