import Cookies from "js-cookie";
import { atom } from "recoil";

const likedActivitiesInCookies = Cookies.get("likedActivities");
const likedActivitiesSyntax = likedActivitiesInCookies
  ?.replaceAll(`\"`, "")
  .replaceAll('"', "")
  .replaceAll("[", "")
  .replaceAll("]", "");
let likedActivitiesArray;

if (likedActivitiesSyntax)
  likedActivitiesArray = likedActivitiesSyntax.split(",");

export const orderState = atom({
  key: "orderState",
  default: "distance_asc" as "distance_asc" | "distance_desc",
});

export const filterState = atom({
  key: "filterState",
  default: "all",
});

export const kindsState = atom({
  key: "kindsState",
  default: [] as String[],
});

export const likedState = atom({
  key: "likedState",
  default: likedActivitiesArray as String[],
});
