import { FC } from "react";
import {
  Container,
  SocialNetworksDiv,
  FooterMiddleText,
  FooterText,
} from "./Footer.style";
import TwitterLogoSvg from "../../assets/twitterLogoSvg";
import FacebookLogoSvg from "../../assets/facebookLogoSvg";
import InstagramLogoSvg from "../../assets/instagramLogoSvg";

const Footer: FC = () => {
  return (
    <Container>
      <FooterText>Activity Hunter</FooterText>
      <FooterMiddleText>© Activites Hunter, Inc. 2021.</FooterMiddleText>
      <SocialNetworksDiv>
        <FooterText>Suivez-nous : </FooterText>
        <a href="https://www.iomentum.com/" target="_blank" rel="noreferrer">
          <TwitterLogoSvg />
        </a>
        <a href="https://github.com/iomentum" target="_blank" rel="noreferrer">
          <InstagramLogoSvg />
        </a>
        <a
          href="https://www.linkedin.com/company/iomentum/about/"
          target="_blank"
          rel="noreferrer"
        >
          <FacebookLogoSvg />
        </a>
      </SocialNetworksDiv>
    </Container>
  );
};

export default Footer;
