import { FC } from "react";
import {
  CardFront,
  CardBack,
  CardContainer,
  StatusDot,
  FrontTitle,
  Title,
  FrontCardContentText,
  CardContentText,
  RowDiv,
  LearnMoreDiv,
  LearnMoreHref,
  HeartDivFront,
  HeartDivBack,
} from "./Card.styles";
import { Activity } from "../../dto/index";
import traductions from "../../utils/traductions.json";
import { ReactComponent as Distance } from "../../assets/distance.svg";
import {
  HeartFrontSvg,
  HeartFrontLikedSvg,
  HeartBackSvg,
  HeartBackLikedSvg,
} from "../../assets";

import Cookies from "js-cookie";

interface SimpleCardProps {
  activity: Activity;
  setLikedActivities: Function;
  likedActivities: String[];
}

const SimpleCard: FC<SimpleCardProps> = (props) => {
  const { activity, setLikedActivities, likedActivities }: SimpleCardProps =
    props;

  const saveLikedActivities = () => {
    let newLikedActivities: String[] = likedActivities ? likedActivities : [];
    const name = activity.name;

    if (!newLikedActivities.includes(name)) {
      newLikedActivities = Object.assign([], newLikedActivities);
      newLikedActivities.push(name);
    } else alert("WIP : heart disable");

    setLikedActivities(newLikedActivities);

    Cookies.set("likedActivities", likedActivities, {
      expires: 365,
    });
  };

  const translateKind = (kind: String) => {
    const kindsTraductions = traductions.find((trad) => trad.id === kind);
    return ` #${kindsTraductions?.fr}`;
  };
  const isLiked = likedActivities?.includes(activity.name);

  const renderActivity = () => {
    if (activity.kinds.length > 3) {
      const activityArray: String[] = [];
      const smallArray: String[] = activity.kinds.slice(0, 3);
      smallArray.map((kind) => activityArray.push(translateKind(kind)));

      return activityArray;
    } else {
      activity.kinds.map((kind) => translateKind(kind));
    }
  };

  return (
    <CardContainer
      backgroundImageUrl={
        activity.imageUrl ||
        "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/dbc54695-b11c-4b1c-a771-435a6a1a6e45/bientt_dispo.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAT73L2G45O3KS52Y5%2F20210805%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20210805T123541Z&X-Amz-Expires=86400&X-Amz-Signature=77c4dcce3bb108fc09054b91efde830a8a307dc77b7566da9c1d1fac663acc87&X-Amz-SignedHeaders=host&response-content-disposition=filename%20%3D%22bientt_dispo.svg%22"
      }
    >
      <CardFront>
        <HeartDivFront>
          {isLiked ? <HeartFrontLikedSvg /> : <HeartFrontSvg />}
        </HeartDivFront>

        <FrontTitle>
          {activity.name.length > 10
            ? activity.name.substring(0, 10) + " ..."
            : activity.name}
        </FrontTitle>

        <RowDiv isSpaceBetween>
          <FrontCardContentText>{renderActivity()}</FrontCardContentText>
        </RowDiv>
      </CardFront>

      <CardBack>
        <HeartDivBack onClick={saveLikedActivities}>
          {isLiked ? <HeartBackLikedSvg /> : <HeartBackSvg />}
        </HeartDivBack>

        <Title isMargintop color="textSecondary">
          {activity.name}
        </Title>
        <RowDiv isSpaceBetween={false}>
          <StatusDot isOpen={activity.business_status === "OPERATIONAL"} />
          <CardContentText isMarginTop>
            {activity.business_status === "OPERATIONAL" ? "Ouvert" : "Fermé"}
          </CardContentText>

          <div
            style={{
              margin: "30px 5px 0 30px",
            }}
          >
            <Distance />
          </div>
          <CardContentText isMarginTop>
            {activity.distance.distanceString}
          </CardContentText>
        </RowDiv>
        <RowDiv isSpaceBetween={false}>
          <LearnMoreDiv>
            <LearnMoreHref href={activity.gmapsLink as string}>
              En savoir plus
            </LearnMoreHref>
          </LearnMoreDiv>
        </RowDiv>
      </CardBack>
    </CardContainer>
  );
};

export default SimpleCard;
