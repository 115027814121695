import { color } from "../../theme/color";
import styled from "styled-components";

interface StyledCardProps {
  backgroundImageUrl?: any;
  isOpen?: boolean;
  isSpaceBetween?: boolean;
}

export const CardFront = styled.div`
  width: 270px;
  height: 189px;
`;
export const CardBack = styled.div`
  width: 270px;
  height: 189px;
  display: none;
  flex-direction: column;
  align-items: center;
`;

export const CardContainer = styled.div<StyledCardProps>`
  width: 270px;
  height: 189px;

  border: solid 2px black;
  border-radius: 10px;x;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${(props) => props.backgroundImageUrl});
  &:hover {
    background-color: ${color.primary};
    background-image: none;
  }
  &:hover ${CardFront} {
    display: none;
  }
  &:hover ${CardBack} {
    display: flex;
  }
`;

export const StatusDot = styled.div<StyledCardProps>`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.isOpen ? color.greenStatus : color.yellowStatus};
  margin: 35px 5px 0 0px;
`;

export const FrontTitle = styled.p`
  width: 180px;
  font-size: 19px;
  color: ${color.fontLighter};
  text-align: left;
  text-transform: capitalize;

  margin-left: 30px;
  margin-top: 116px;
  display: flex;
  flex-wrap: wrap;
`;

export const Title = styled.p`
  font-size: 19px;
  color: ${color.font};
  text-align: center;
  width: 162px;
  border: 1px solid ${color.secondary};
  text-transform: capitalize;
  margin-top: ${(props: { isMargintop: boolean }) =>
    props.isMargintop ? 30 : 0}px;
`;

export const FrontCardContentText = styled.p`
  font-size: 17px;
  color: ${color.font};
  display: flex;
  margin-left: 30px;
  width: 180px;
  word-break: break-all;
`;

export const CardContentText = styled.p`
  font-size: 17px;
  color: ${color.font};
  text-align: center;
  margin-top: ${(props: { isMarginTop: boolean }) =>
    props.isMarginTop ? 30 : 0}px;
`;

export const RowDiv = styled.div<StyledCardProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.isSpaceBetween ? "space-between" : "none"};
`;

export const LearnMoreDiv = styled.div`
  width: 115px;
  border-radius: 10px;
  background-color: ${color.secondary};
  text-decoration: underline;
  text-decoration-color: white;
  cursor: pointer;
  align-items: center;
  margin-top: 15%;
`;

export const LearnMoreHref = styled.a`
  font-size: 14px;
  font-weight: 600;
  color: ${color.font};
  margin-left: 14px;
  text-align: center;
`;

export const HeartDivFront = styled.div`
  margin-top: 35px;
  margin-left: 223px;
  cursor: pointer;
  position: absolute;
`;

export const HeartDivBack = styled.div`
  margin-top: 151px;
  margin-left: 200px;
  cursor: pointer;
  position: absolute;
`;
