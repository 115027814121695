import React, { FC, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Input, LocationSuggestion } from "./Form.style";
import { color } from "../../theme";
import { useCallback } from "react";

export interface LocationFieldProps {
  setAddress: (newValue: object) => void;
  fieldAddress: string;
  setFieldAddress: (newValue: string) => void;
}

const LocationField: FC<LocationFieldProps> = (props) => {
  const { setAddress, fieldAddress, setFieldAddress }: LocationFieldProps =
    props;

  const handleSelect = useCallback(
    (address: string) => {
      setFieldAddress(address);

      geocodeByAddress(address)
        .then(async (results: any) => {
          let finalObject = {
            addressDetails: {},
            coordinates: {},
            formatted_address: undefined,
          };

          const addressDetails = results[0].address_components.reduce(
            (acc: any, current: any) => {
              acc[current.types[0]] = current.long_name;
              return acc;
            },
            {}
          );

          finalObject.addressDetails = addressDetails;
          finalObject.formatted_address = results[0].formatted_address;
          finalObject.coordinates = await getLatLng(results[0]);

          return finalObject;
        })
        .then(setAddress)
        .catch((error: any) => console.error("Error", error));
    },
    [setAddress]
  );

  return (
    <PlacesAutocomplete
      value={fieldAddress}
      onChange={setFieldAddress}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <Input
            {...getInputProps({
              placeholder: "Adresse de départ",
              className: "location-search-input",
            })}
          />
          <div style={{ position: "relative", display: "flex" }}>
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index: number) => {
              return (
                <LocationSuggestion
                  {...getSuggestionItemProps(suggestion, {
                    style: {
                      backgroundColor: suggestion.active
                        ? color.main
                        : color.font,
                      color: suggestion.active ? color.font : color.main,
                      marginTop: `${index * 50}px`,
                    },
                    key: index,
                  })}
                >
                  <span>{suggestion.description}</span>
                </LocationSuggestion>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationField;
