import styled from "styled-components";
import { color } from "../../theme";

export const Container = styled.div`
  height: 71px;
  background-color: ${color.main};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px;
`;

export const SocialNetworksDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 210px;
`;

export const FooterMiddleText = styled.p`
  color: ${color.primary};
  font-family: Lato;
`;

export const FooterText = styled.p`
color ${color.font};
font-family: Lato;
`;
