import { FC } from "react";
import {
  Container,
  HeaderBox,
  Image,
  LogoBox,
  Logo,
  MiddleText,
} from "./Header.style";
import Form from "../Form/Form";
import WeatherCard from "../WeatherCard/WeatherCard";
import { apiResponseState } from "../../store/activities.store";
import { useRecoilValue } from "recoil";
import HeaderBg from "../../assets/header-bg.png";

const Header: FC = () => {
  const apiResponse = useRecoilValue(apiResponseState);

  const dataReceived = !!apiResponse.baseData;

  return (
    <Container dataReceived={dataReceived}>
      <Image src={HeaderBg} alt="header-bg" dataReceived={dataReceived} />
      <HeaderBox dataReceived={dataReceived}>
        <LogoBox>
          <Logo />
          Suricate
        </LogoBox>
      </HeaderBox>
      <MiddleText>
        Activités en temps réel basées sur ta localisation
      </MiddleText>
      <Form />
      {dataReceived && <WeatherCard />}
    </Container>
  );
};

export default Header;
