import React from "react";

function TwitterLogoSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      fill="none"
      viewBox="0 0 31 31"
    >
      <path
        fill="#B3442B"
        d="M15.997.865c-8.283 0-15 6.717-15 15 0 8.284 6.717 15 15 15 8.284 0 15-6.716 15-15 0-8.283-6.716-15-15-15zm7.209 11.307c.01.157.01.322.01.482 0 4.916-3.744 10.577-10.584 10.577-2.11 0-4.065-.612-5.712-1.667.301.033.59.047.897.047a7.46 7.46 0 004.618-1.587 3.72 3.72 0 01-3.476-2.578c.573.083 1.088.083 1.678-.067a3.716 3.716 0 01-2.977-3.65v-.047a3.72 3.72 0 001.677.472A3.72 3.72 0 017.68 11.06c0-.693.18-1.325.506-1.874a10.556 10.556 0 007.667 3.887c-.526-2.528 1.363-4.574 3.633-4.574 1.071 0 2.036.449 2.715 1.172a7.346 7.346 0 002.36-.894 3.701 3.701 0 01-1.633 2.046 7.352 7.352 0 002.143-.58 7.796 7.796 0 01-1.865 1.93z"
      ></path>
    </svg>
  );
}

export default TwitterLogoSvg;
