import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { RecoilRoot } from "recoil";
import MainPage from "./pages/MainPage";

function App() {
  return (
    <RecoilRoot>
      <Helmet>
        <title>Suricate</title>
      </Helmet>
      <BrowserRouter>
        <Route exact path="/" component={MainPage} />
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
