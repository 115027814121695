import styled from "styled-components";
import { color } from "../../theme";
import { ReactComponent as LogoSvg } from "../../assets/suricate-logo.svg";

interface HeaderProps {
  dataReceived: boolean;
}

export const Container = styled.div<HeaderProps>`
  height: ${(props) => (props.dataReceived ? "40" : "100")}%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.dataReceived ? "flex-end" : "center")};
  align-items: center;
  color: ${color.font};
`;

export const Image = styled.img<HeaderProps>`
  position: absolute;
  height: ${(props) => (props.dataReceived ? "40%" : "auto")};
  height: 100vh;
  max-height: 100vh;
  object-fit: cover;
  width: 100%;
`;

export const HeaderBox = styled.div<HeaderProps>`
  position: absolute;
  top: 0px;
  height: ${(props) => (props.dataReceived ? "50px" : "104px")};
  background-color: ${color.main}0D;
  display: flex;
  align-items: center;
  width: 100%;
  backdrop-filter: blur(6px);
`;

export const LogoBox = styled.span`
  padding-left: 60px;
  display: flex;
  align-items: center;
  font-family: Rufina;
  font-size: 24px;
`;

export const Logo = styled(LogoSvg)`
  margin-right: 15px;
`;

export const MiddleText = styled.span`
  margin-bottom: 15vh;
  font-size: 68px;
  opacity: 1;
  width: 50%;
  text-align: center;
  z-index: 1;
`;
