import styled from "styled-components";
import { color } from "../../theme";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${color.main};
`;

export const FilterBox = styled.div`
  margin: 45px;
  border-radius: 10px;
  border: 1px solid ${color.primary};
  padding: 6px 20px;
  display: flex;
  color: ${color.primary};
`;

export const Select = styled.select`
  background: transparent;
  border: none;
  color: ${color.font};
  margin-left: 20px;
  text-transform: capitalize;
`;

export const Option = styled.option`
  color: black;
`;
