import styled from "styled-components";
import { color } from "../../theme";

export const Container = styled.div`
  background: ${color.main}99;
  border-radius: 10px;
  padding: 19px;
  display: flex;
  color: ${color.font};
  align-items: center;
  margin: 25px 0;
  z-index: 1;
`;

interface TextProps {
  fontSize: number;
}

export const Text = styled.span<TextProps>`
  font-size: ${(props) => props.fontSize || 30}px;
`;

export const ForecastBox = styled.span`
  display: flex;
  align-items: flex-start;
`;

export const MiddleInfosBox = styled.span`
  margin: 0px 30px;
`;
