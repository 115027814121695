import { FC, useMemo } from "react";
import { Container, GridBox } from "./Activities.style";
import Card from "../Card/Card";
import { useRecoilState, useRecoilValue } from "recoil";
import { apiResponseState } from "../../store/activities.store";
import {
  filterState,
  orderState,
  likedState,
} from "../../store/activities.filter.store";

const Activities: FC = () => {
  const [apiResponse] = useRecoilState(apiResponseState);
  const [order] = useRecoilState(orderState);
  const selectedFilter = useRecoilValue(filterState);
  const [likedActivities, setLikedActivities] = useRecoilState(likedState);

  const storedActivities = useMemo(
    () => apiResponse.activities || [],
    [apiResponse]
  );

  return (
    <Container>
      <h1>{`Les activités à ${apiResponse.baseData.addressDetails.locality}`}</h1>
      <GridBox>
        {storedActivities
          .filter((activity) =>
            selectedFilter === "all"
              ? true
              : activity.kinds.includes(selectedFilter)
          )
          .sort((a, b) =>
            order === "distance_asc"
              ? a.distance.distanceValue - b.distance.distanceValue
              : b.distance.distanceValue - a.distance.distanceValue
          )
          .map((activity) => (
            <Card
              setLikedActivities={setLikedActivities}
              likedActivities={likedActivities}
              key={activity.id as string}
              activity={activity}
            />
          ))}
      </GridBox>
    </Container>
  );
};

export default Activities;
