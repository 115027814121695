import { FC } from "react";
import { Page } from "./MainPage.style";
import Header from "../components/Header/Header";
import SortingPreferences from "../components/SortingPreferences/SortingPreferences";
import Activities from "../components/Activities/Activities";
import Footer from "../components/Footer/Footer";
import { useRecoilValue } from "recoil";
import { apiResponseState } from "../store/activities.store";

const MainPage: FC = () => {
  const apiResponse = useRecoilValue(apiResponseState);

  const dataReceived = !!apiResponse.baseData;

  return (
    <Page>
      <Header />
      {dataReceived && (
        <div>
          <SortingPreferences />
          <Activities />
          <Footer />
        </div>
      )}
    </Page>
  );
};

export default MainPage;
