import React from "react";

function InstagramLogoSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      fill="none"
      viewBox="0 0 31 31"
    >
      <path
        fill="#B3442B"
        d="M15.36 10.338a5.013 5.013 0 00-5.002 5.002 5.013 5.013 0 005.002 5.002 5.013 5.013 0 005.002-5.002 5.013 5.013 0 00-5.002-5.002zm15.002 5.002c0-2.072.019-4.124-.098-6.192-.116-2.401-.664-4.533-2.42-6.29-1.76-1.759-3.888-2.303-6.29-2.42-2.07-.116-4.123-.097-6.19-.097-2.072 0-4.125-.019-6.192.098-2.402.116-4.533.664-6.29 2.42C1.123 4.619.58 6.747.463 9.149c-.116 2.07-.097 4.123-.097 6.19 0 2.068-.02 4.125.097 6.193.117 2.401.664 4.533 2.42 6.289 1.76 1.76 3.888 2.304 6.29 2.42 2.071.117 4.124.098 6.191.098 2.072 0 4.124.019 6.192-.098 2.401-.116 4.533-.664 6.289-2.42 1.76-1.76 2.304-3.888 2.42-6.29.12-2.067.098-4.12.098-6.191zM15.36 23.036a7.685 7.685 0 01-7.697-7.696 7.685 7.685 0 017.697-7.697 7.686 7.686 0 017.696 7.697 7.686 7.686 0 01-7.697 7.696zm8.011-13.91a1.795 1.795 0 01-1.797-1.798c0-.994.803-1.797 1.797-1.797a1.795 1.795 0 011.661 2.485 1.794 1.794 0 01-1.661 1.11z"
      ></path>
    </svg>
  );
}

export default InstagramLogoSvg;
