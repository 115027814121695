import { FC, useMemo } from "react";
import {
  Container,
  ForecastBox,
  MiddleInfosBox,
  Text,
} from "./WeatherCard.style";
import { ReactComponent as WeatherIcon } from "./weather-icon.svg";
import { apiResponseState } from "../../store/activities.store";
import { useRecoilValue } from "recoil";
import codes from "../../utils/weather-codes.json";

const WeatherCard: FC = () => {
  const apiResponse = useRecoilValue(apiResponseState);

  const baseData = apiResponse.baseData;

  const temperatureAverage = useMemo(
    () =>
      Math.round(
        (parseFloat(`${baseData.forecast.tmax}`) +
          parseFloat(`${baseData.forecast.tmin}`)) /
          2
      ),
    [baseData.forecast.tmax, baseData.forecast.tmin]
  );

  const weatherSpecs = useMemo(
    () => codes.find((code) => code.code === baseData.forecast.weatherCode),
    [baseData.forecast.weatherCode]
  );

  return (
    <Container>
      {/* TODO: Change this image dynamically */}
      <WeatherIcon />
      <MiddleInfosBox>
        <Text fontSize={30}>{baseData.addressDetails.locality}</Text>
        <br />
        <Text fontSize={24}>{weatherSpecs?.label}</Text>
      </MiddleInfosBox>

      <ForecastBox>
        <Text fontSize={48}>{temperatureAverage}</Text>
        <Text fontSize={30}>°C</Text>
      </ForecastBox>
    </Container>
  );
};

export default WeatherCard;
