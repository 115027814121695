import { FC } from "react";
import {
  Container,
  FilterBox,
  Select,
  Option,
} from "./SortingPreferences.style";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  filterState,
  orderState,
  kindsState,
} from "../../store/activities.filter.store";
import traductions from "../../utils/traductions.json";

const orderItems = [
  {
    id: "distance_asc",
    name: "Distance croissante",
  },
  {
    id: "distance_desc",
    name: "Distance décroissante",
  },
];

const translateKind = (kind: String) => {
  const kindsTraductions = traductions.find((trad) => trad.id === kind);
  return `${kindsTraductions?.fr}`;
};

const SortingPreferences: FC = () => {
  const [order, setOrder] = useRecoilState(orderState);
  const [selectedKind, setSelectedKind] = useRecoilState(filterState);
  const kinds = useRecoilValue(kindsState);

  return (
    <Container>
      <FilterBox>
        Filtrer :
        <Select
          name="filters"
          id="type-select"
          value={selectedKind}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
            setSelectedKind(event.target.value as string)
          }
          style={{ width: 300 }}
          defaultValue=""
        >
          {kinds.map((item, index) => (
            <Option key={index} value={item as string}>
              {translateKind(item).charAt(0).toUpperCase() +
                translateKind(item).slice(1)}
            </Option>
          ))}
        </Select>
      </FilterBox>

      <FilterBox>
        Ordre :
        <Select
          name="orders"
          id="type-select"
          value={order}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
            setOrder(event.target.value as "distance_asc" | "distance_desc")
          }
          style={{ width: 300 }}
          defaultValue=""
        >
          {orderItems.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </FilterBox>
    </Container>
  );
};

export default SortingPreferences;
