export const HeartBackLikedSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="21"
      viewBox="0 0 23 21"
    >
      <path
        stroke="#738994"
        fill="#738994"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6.292 1.125c-2.876 0-5.209 2.308-5.209 5.156 0 2.3.912 7.756 9.884 13.271a1.026 1.026 0 001.066 0c8.972-5.515 9.884-10.972 9.884-13.27 0-2.849-2.333-5.157-5.209-5.157-2.876 0-5.208 3.125-5.208 3.125S9.168 1.125 6.292 1.125z"
      ></path>
    </svg>
  );
};
