import styled from "styled-components";
import { color } from "../../theme";

export const Container = styled.div`
  background: ${color.main}99;
  width: 50vw;
  border-radius: 10px;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const Field = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  border-right: solid;
  border-color: white;
  border-width: 1px;
  margin-left: 20px;
  height: 100%;
`;

export const Button = styled.div`
  background: ${color.primary};
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px;
`;

export const LocationSuggestion = styled.div`
  position: absolute;
  cursor: pointer;
  zindex: 99;
  height: 50px;
  display: flex;
  alignitems: center;
  width: 100%;
  margin-left: 20px;
`;

export const Input = styled.input`
  background: transparent;
  border: none;
  width: 100%;
  height: 40px;
  color: ${color.font};
  font-weight: bold;
  margin-left: 20px;
  font-size: 25px;
`;

export const Select = styled.select`
  background: transparent;
  border: none;
  width: 85%;
  height: 40px;
  color: ${color.font};
  font-weight: bold;
  margin-left: 20px;
  font-size: 25px;
`;

export const Option = styled.option`
  background: ${color.font};
  font-family: Lato;
  color: ${color.main};
  cursor: pointer;
  height: 50px;
  width: 100%;
`;
